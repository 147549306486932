<template>
  <div id="app">
    <div id="local-teacherCamera"></div>
    <video id="backgroundVideo" crossorigin="anonymous" autoplay playsinline muted loop src="http://vjs.zencdn.net/v/oceans.mp4"></video>
  </div>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng"
import VirtualBackgroundExtension from "agora-extension-virtual-background"
export default {
  name: 'App',
  data() {
    return {
      agoraAppId: '1e259154d42d4dec8ab118f4aa8a17db',
      agoraChannel: '777',
      agoraToken: '007eJxTYDjd8txk/SHu5/vmKVUsD7whcSK10lxE6cKm8rtykp8XmSxTYDBMNTK1NDQ1STExSjFJSU22SEwyNLRIM0lMtEg0NE9JevfVP60hkJFBpvsfAyMUgvjMDObm5gwMAIdhIB4=',
      agoraClient: null,
      localCameraClient: null,
      localMicrophoneClient: null,
      agoraCameraClient: null,
      processorClient: null
    }
  },
  created() {
    this.agoraInit()
  },
  methods: {
    // 初始化
    async agoraInit() {
      // 初始化 创建实例
      this.agoraClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })
      // 创建 VirtualBackgroundExtension 实例
      const extension = new VirtualBackgroundExtension();
      // 检查兼容性
      if (!extension.checkCompatibility()) {
        // 当前浏览器不支持虚拟背景插件，你可以停止执行之后的逻辑
        console.error("Does not support Virtual Background!");
      }
      // 注册插件
      AgoraRTC.registerExtensions([extension]);
      
      // 创建 VirtualBackgroundProcessor 实例
      this.processorClient = extension.createProcessor();
      await this.processorClient.init();
      // 加入頻道
      await this.agoraClient.join(this.agoraAppId, this.agoraChannel, this.agoraToken);
      // 開啟視訊
      this.agoraCamera()
    },
    async agoraCamera() {
      // 創建視頻流
      this.localCameraClient = await AgoraRTC.createCameraVideoTrack();
      // 創建音頻流
      this.localMicrophoneClient = await AgoraRTC.createMicrophoneAudioTrack();
      // 将插件注入 SDK 内的视频处理管道
      this.localCameraClient.pipe(this.processorClient).pipe(this.localCameraClient.processorDestination);
      const backgroundVideo = document.getElementById('backgroundVideo')
      this.processorClient.setOptions({
        type: 'video',
        source: backgroundVideo,
        blurDegree: 1,
        fit: 'cover'
      })
      await this.processorClient.enable();
      // 加入訂閱推流
      await this.agoraClient.publish([this.localCameraClient, this.localMicrophoneClient]);
      // 播放本地視頻
      this.localCameraClient.play("local-teacherCamera");
    }
  }
}
</script>

<style>
#app {
  display: flex;
  justify-content: center;
}
#local-teacherCamera {
  width: 720px;
  height: 480px;
}
#backgroundVideo {
  width: 0;
}
</style>
